@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.container {
  position: fixed;
  top: 88px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $blue-900;
  text-align: center;
  padding: 16px 0;
  width: 100%;
  z-index: 11;
  animation-name: moveInTop;
  animation-duration: 5s;
  animation-timing-function: ease-out;
}

.pending {
  background-color: $orange;
}

.error {
  background-color: $cyan;
}

.success {
  background-color: $green;
}

@include sm {
  .container {
    top: 56px;
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-88px);
  }

  7% {
    opacity: 1;
    transform: translateY(0rem);
  }
  93% {
    opacity: 1;
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(-88px);
    opacity: 0;
  }
}
