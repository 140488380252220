@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.root {
  max-width: 375px;
  width: 100%;
}

.card {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
  background: white;
  max-width: 375px;
  width: calc(100%);

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  ul {
    list-style: none;
    max-height: 336px;
    height: 100%;
    overflow-y: scroll;

    li {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px;
      border-bottom: 1px solid $slate-100;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      .alertMessage {
        flex-basis: 73%;
        text-align: left;

        p {
          font-size: 14px;
          line-height: 20px;
          color: $slate-500;
          font-family: $font-family-roboto;
        }

        sub {
          font-weight: 300;
          font-size: 12px;
          line-height: 22px;
          font-family: $font-family-roboto;
          color: $slate-500;
          display: flex;
        }
      }
    }
  }

  .dotWrapper {
    width: 10px;
  }

  .dot {
    height: 10px;
    width: 10px;
    background: $cyan;
    display: block;
    border-radius: 50%;
  }
}

.container {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.popperHidden {
  visibility: hidden;
  pointer-events: none;
}

@include sm {
  .root {
    transform: translate(16px, 60px) !important;
    width: 90%;
  }
}
