@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.container {
  display: flex;
  gap: 10px;
  span {
    color: $slate;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
  }

  a {
    text-decoration: none;
    span {
      color: $blue;
    }
  }
}

@include md {
  .container {
    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@include sm {
  .container {
    span {
      font-size: 12px;
      line-height: 22px;
    }

    .mobileHidden {
      display: none;
    }
  }
}
