//Media Queries
@mixin sm {
  @media all and (max-width: 463px) {
    @content;
  }
}

@mixin md {
  @media all and (max-width: 768px) {
    @content;
  }
}


//Mixins general
@mixin get-image($name) {
  background-image: url('../public/images/'+$name+'.svg');
}