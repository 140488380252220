@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.container {
  position: fixed;
  z-index: 7;
  background-color: rgba(242, 244, 247, 0.85);
  width: 100%;
  height: 88px;
  padding: 24px 80px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  @supports (backdrop-filter: none) {
    backdrop-filter: blur(24px);
  }

  @supports (-webkit-backdrop-filter: none) {
    -webkit-backdrop-filter: blur(24px);
  }

  @supports (not (backdrop-filter: none)) and
    (not (-webkit-backdrop-filter: none)) {
    background-color: rgb(242, 244, 247);
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .logo {
    height: 24px;
  }

  .profile {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

@include md {
  .container {
    padding: 24px 32px;
  }
}

@include sm {
  .container {
    height: 56px;
    padding: 16px;

    .logo {
      width: 24px;
      height: 24px;
    }

    .profile {
      gap: 12px;
    }
  }
}
