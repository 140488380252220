@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.container {
  text-decoration: none;

  div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-image: url('/images/avatar-base.svg');
    background-size: cover;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-family: $font-family-montserrat;
  }

  @include sm {
    div {
      font-size: 13px;
      line-height: 24px;
      width: 24px;
      height: 24px;
    }
  }
}
