@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.button {
  font-family: $font-family-montserrat;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  box-sizing: border-box;
  transition: background $fast-transition ease-in-out,
    color $fast-transition ease-in-out;
  &:disabled {
    cursor: not-allowed;
  }
}

$shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $blue-200;

.xxs {
  font-size: 10px;
  line-height: 22px;
  padding: 1px 8px;
}

.xs {
  font-size: 12px;
  line-height: 22px;
  padding: 6px 12px 6px 13px;
}

.sm {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 14px;
}

.md {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 16px;
}

.lg {
  font-size: 16px;
  line-height: 26px;
  padding: 10px 18px;
}

.xl {
  font-size: 16px;
  line-height: 26px;
  padding: 12px 20px;
}

.xxl {
  font-size: 18px;
  line-height: 32px;
  padding: 16px 28px;
}

.fullWidth {
  width: 100%;
}

.default {
  @extend .button;
  color: $blue;
  background: none;
  &:hover {
    color: $blue-600;
  }

  &:focus,
  &:active {
    color: $blue-600;
  }

  &:disabled {
    color: $blue-300;
  }
}

.default-slate {
  @extend .button;
  color: $slate-500;
  background: none;
  &:hover {
    color: $slate-600;
  }

  &:focus,
  &:active {
    color: $slate-500;
  }

  &:disabled {
    color: $slate-300;
  }
}

.blue {
  @extend .button;
  color: white;
  background: $blue;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  &:hover {
    background: $blue-600;
  }

  &:focus,
  &:active {
    box-shadow: $shadow;
    background: $blue-600;
  }

  &:disabled {
    background: $blue-300;
  }
}

.marine {
  @extend .button;
  color: white;
  background: $blue;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  &:hover {
    background: $blue-600;
  }

  &:focus,
  &:active {
    box-shadow: $shadow;
    background: $blue-600;
  }

  &:disabled {
    background: $slate;
  }
}

.thumbnail {
  @extend .button;
  color: $slate-50;
  background: $blue;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  transition: background $fast-transition ease-in-out,
    color $fast-transition ease-in-out;
  &:hover {
    background: $blue-600;
  }

  &:disabled {
    background: $slate;
  }

  &:focus,
  &active {
    background: $blue-300;
  }
}

.gray {
  @extend .button;
  color: $slate;
  background: $slate-100;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  &:hover {
    background: $slate-200;
  }

  &:focus,
  &:action {
    box-shadow: $shadow;
    background: $slate-200;
  }

  &:disabled {
    background: $slate-100;
    color: $slate-400;
  }
}

.black {
  @extend .button;
  color: $slate-300;
  background: $slate;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  &:hover {
    background: $slate-800;
    color: white;
  }

  &:focus,
  &:action {
    box-shadow: $shadow;
    color: white;
    background: $slate-800;
  }

  &:disabled {
    color: white;
    background: $slate-300;
  }
}

.outlined {
  @extend .button;
  border: 1px solid $blue;
  color: $blue;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background: white;
  &:hover {
    border: 1px solid $blue-600;
    color: $blue-600;
  }

  &:focus,
  &:active {
    box-shadow: $shadow;
    border: 1px solid $blue-600;
    color: $blue-600;
  }

  &:disabled {
    border: 1px solid $blue-300;
    color: $blue-300;
  }
}

.error {
  @extend .button;
  border: 1px solid $red;
  color: white;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  transition: border $fast-transition ease-in-out,
    color $fast-transition ease-in-out;
  background: $red;
  &:hover {
    border: 1px solid $red-600;
    background: $red-600;
  }

  &:focus {
    box-shadow: $shadow;
    border: 1px solid $red;
  }

  &:disabled {
    border: 1px solid $red-300;
  }
}

.iconButton {
  display: flex;
  gap: 8px;
  align-items: center;
}
